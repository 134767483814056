import { Skeleton } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { lazy, Suspense, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
	BrowserRouter,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from "react-router-dom";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { FootNav } from "./components/Navigation/FootNav";
import { TopNav } from "./components/Navigation/TopNav";
import { WindowWidthProvider } from "./Context/WindowWidth.context";
import { useDevToolsStatus } from "./hooks/useDevToolsDetect";

// Lazy loading pages
const AnimeListPage = lazy(() => import("./Pages/AnimeListPage"));
const DetailsPage = lazy(() => import("./Pages/DetailsPage"));
const GenreResultsPage = lazy(() => import("./Pages/GenreResultsPage"));
const Home = lazy(() => import("./Pages/Home"));
const NotFoundPage = lazy(() => import("./Pages/NotFoundPage"));
const SearchPage = lazy(() => import("./Pages/SearchPage"));
const TypeResultsPage = lazy(() => import("./Pages/TypeResultsPage"));
const WatchPage = lazy(() => import("./Pages/WatchPage"));

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

const AppRoutes = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const isDevToolsOpen = useDevToolsStatus();

	useEffect(() => {
		isDevToolsOpen && navigate("/errorCloseConsole");
	}, [isDevToolsOpen, navigate]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	return (
		<Suspense
			fallback={
				<Skeleton
					variant="rectangular"
					animation="pulse"
					sx={{ height: "100%", width: "100%" }}
				/>
			}
		>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/search" element={<SearchPage />} />
				<Route path="/anime/:id" element={<DetailsPage />} />
				<Route path="/watch/:id/:episodeId" element={<WatchPage />} />
				<Route path="/type/:id" element={<TypeResultsPage />} />
				<Route path="/genre/:id" element={<GenreResultsPage />} />
				<Route path="/list" element={<AnimeListPage />} />
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</Suspense>
	);
};

const AppLayout = () => (
	<div
		dir="ltr"
		style={{ height: "100vh", display: "flex", flexDirection: "column" }}
	>
		<TopNav />
		<div style={{ flex: 1 }}>
			<AppRoutes />
		</div>
		<FootNav />
	</div>
);

const App = () => (
	<>
		<Helmet>
			<meta
				httpEquiv="Content-Security-Policy"
				content={`
          default-src 'self' https://*.anyani.me https://anyani.me;
          script-src 'self' https://disqus.com https://*.disqus.com https://anyani-me.disqus.com https://c.disquscdn.com;
          img-src https:;
          font-src 'self' https: data:;
          frame-src https://awish.pro https://www.yourupload.com https://www.mp4upload.com https://dood.wf https://alions.pro https://hydrax.net https://abysscdn.com https://disqus.com https://*.disqus.com;
          style-src 'self' https: 'unsafe-inline';
        `}
			/>
		</Helmet>
		<ErrorBoundary>
			<BrowserRouter>
				<QueryClientProvider client={queryClient}>
					<WindowWidthProvider>
						<AppLayout />
					</WindowWidthProvider>
				</QueryClientProvider>
			</BrowserRouter>
		</ErrorBoundary>
	</>
);

export default App;
